import React from "react";
import Layout from "../components/layout/layout";
import Form from "../components/form/form";
import "./contact.scss";
// import { Link } from "gatsby";

const ContactPage = () => {

  return (
    <Layout
      title="Contact"
      description="This is the contact page."
    >
      <Form/>
    </Layout>
  );
};

export default ContactPage;
