import React from "react";
import "./form.scss";

export default function Form() {

  return (
    <section className="contact-form">
      <div className="container">
        <form name="contact" method="POST" data-netlify="true" netlify-honeypot="bot-field">
          <input type="checkbox" name="bot-field" value="1" style={{ display: "none" }} tabindex="-1" autocomplete="off"/>
          <input type="hidden" name="form-name" value="contact" />
          <input type="text" name="firstName" placeholder="First Name"/>
          <input type="text" name="lastName" placeholder="Last Name"/>
          <input type="text" name="email" placeholder="Email"/>
          <textarea name="message" placeholder="Message" cols="40" rows="8"/>
          <div data-netlify-recaptcha="true"/>
          <input type="submit" value="Submit"/>
        </form>
      </div>
    </section>
  );
}
